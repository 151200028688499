<template>
  <v-dialog v-model="dialog" max-width="550" scrollable>
    <template #activator="{ on: dialog }">
      <v-tooltip bottom>
        <template #activator="{ on: tooltip }">
          <div class="mr-1">
            <v-btn tile icon v-on="{ ...tooltip, ...dialog }">
              <img height="15" width="15" :src="columnsImg" alt="columns icon" />
            </v-btn>
          </div>
        </template>
        <span>Gestionar columnas</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="text-h5"> Gestión de columnas </v-card-title>

      <v-card-text>
        <v-row class="mt-1">
          <v-col>
            <draggable
              v-model="handledHeaders"
              v-bind="dragOptions"
              group="headers"
              tag="div"
              handle=".handle"
            >
              <v-row
                v-for="header in handledHeaders"
                :key="header.value"
                class="align-center justify-space-between"
                :class="{ 'd-none': isActionColumn(header) }"
                dense
              >
                <v-col cols="auto">
                  <v-icon class="handle" small>drag_indicator</v-icon>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="header.visible"
                    hide-details
                    class="mt-0 ml-md-3 pt-0"
                    :label="header.text"
                  />
                </v-col>
                <v-col cols="3">
                  <v-select
                    v-model="header.width"
                    :items="headersWidth"
                    dense
                    hide-details
                    label="ancho"
                    class="my-2"
                  />
                </v-col>
              </v-row>
            </draggable>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="closeDialog" class="mr-2"> Cancelar </v-btn>
        <v-btn color="primary" @click="saveColumnData"> Aplicar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {
    draggable: () => import("vuedraggable"),
  },
  props: {
    crudName: { type: String, default: "" },
    headers: { type: Array, default: () => [] },
    list: { type: Array, default: () => [] },
  },
  data: () => ({
    dialog: false,
    columnsImg: require("@/assets/icons/columns.svg"),
    handledHeaders: null,
    headersWidth: [
      { text: "auto", value: null },
      { text: "XS (75)", value: "75" },
      { text: "S (100)", value: "100" },
      { text: "M (200)", value: "200" },
      { text: "L (300)", value: "300" },
      { text: "XL (400)", value: "400" },
    ],
  }),
  methods: {
    saveColumnData() {
      let cleanColumns = JSON.parse(JSON.stringify(this.handledHeaders));
      const actionsIndex = cleanColumns.findIndex(c => c.value === "actions") || null;
      if (actionsIndex) {
        cleanColumns.splice(actionsIndex);
      }
      this.$emit("updateHeadersSpecs", this.handledHeaders, this.crudName);
      this.closeDialog();
    },
    closeDialog() {
      this.dialog = false;
      this.resetHeaders();
    },
    resetHeaders() {
      this.handledHeaders = JSON.parse(JSON.stringify(this.headers));
    },
    isActionColumn(header) {
      return header.value.includes("actions") || header.value.includes("Actions");
    },
  },
  watch: {
    headers: {
      handler() {
        this.resetHeaders();
      },
      deep: true,
    },
  },
  beforeMount() {
    this.resetHeaders();
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        ghostClass: "ghost",
      };
    },
  },
};
</script>

<style scoped>
.handle {
  cursor: grab;
}
</style>
